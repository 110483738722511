import React from 'react';
import { useState, useEffect } from 'react';
import deleteIcon from '../assets/delete.svg';

const DeleteModal = ({ onClose, db, resIndex }) => {

  const handleDelete = async (key) => {
    if (window.confirm(`${key} kategorisini silmek istediğinize emin misiniz?`)) {
      const updatedMenu = db[resIndex]["menu"];
      delete updatedMenu[key];
      let authToken = localStorage.getItem('authToken');
      if (!authToken) {
        const urlParams = new URLSearchParams(window.location.search);
        authToken = urlParams.get('token');
        if (authToken) {
          localStorage.setItem('authToken', authToken);
        } else {
          alert('Authorization token is missing.');
          return;
        }
      }
      await fetch('https://next-backend-two.vercel.app/api/handleedit', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${authToken}`
        },
        body: JSON.stringify({
          id: db[resIndex].id,
          updatedPart: "menu",
          updatedValue: updatedMenu,
        }),
      });
      window.location.reload();
    }
  }

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
      <div className="bg-menuBG p-4 rounded-md md:w-[25%] w-[50%]">
        <h2 className="text-xl mb-4 text-white">Kategori Sil</h2>
        <ul className="text-white mb-4">
          {Object.keys(db[resIndex]["menu"]).map((key) => (
            <li key={key} className="font-semibold flex gap-2 items-center">{key}<button onClick={() => handleDelete(key)}><img src={deleteIcon} className='md:h-6 h-5'></img></button></li>
          ))}
        </ul>
        <button className="bg-red-500 rounded-md px-4 py-2" onClick={onClose}>
          Kapat
        </button>
      </div>
    </div>
  );
};

export default DeleteModal;