import React, { useEffect, useState } from 'react';
import edit from '../assets/edit_2.svg';
import deleteIcon from '../assets/delete.svg';
import info from '../assets/info.svg';

export default function MenuItem(props) {
  const [dbData, setDbData] = useState(props.db); // Database data is used to store the data from the database.
  const [label, setLabel] = useState(""); // State variable to store the label
  const [labelPosition, setLabelPosition] = useState({ top: 0, left: 0 }); // State variable to store the label position

  const handleEdit = async (event, editing_part, index) => {
    event.stopPropagation(); // Stops the click event from propagating to the parent element
    const current_value = props.submenu[editing_part][index];
    let new_value = prompt(`${editing_part === "Ürünler" ? "Ürün" : "Fiyat"} için yeni değeri girin (şuanki değer: ${current_value}):`, current_value);
    
    if(editing_part === "Fiyatlar"){new_value = parseInt(new_value, 10);}
    if (editing_part==="Fiyatlar" && isNaN(new_value)) {
      alert("Geçersiz değer girdiniz. Lütfen bir sayı girin.");
      return;
    }

    if (editing_part === "Ürünler" && new_value && new_value !== current_value) {
      const updatedDbData = [...dbData];
      updatedDbData[props.id-1]["menu"][props.subName]["Ürünler"][index] = new_value
      setDbData(updatedDbData);
      let authToken = localStorage.getItem('authToken');
      if (!authToken) {
        const urlParams = new URLSearchParams(window.location.search);
        authToken = urlParams.get('token');
        if (authToken) {
          localStorage.setItem('authToken', authToken);
        } else {
          alert('Authorization token is missing.');
          return;
        }
      }
      await fetch('https://next-backend-two.vercel.app/api/handleedit', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${authToken}`
        },
        body: JSON.stringify({
          id: props.id,
          updatedPart: "menu",
          updatedValue: updatedDbData[props.id-1]["menu"],
        }),
      });
    }
    if (editing_part === "Fiyatlar" && new_value && new_value !== current_value) {
      const updatedDbData = [...dbData];
      updatedDbData[props.id-1]["menu"][props.subName]["Fiyatlar"][index] = new_value;
      setDbData(updatedDbData);
      let authToken = localStorage.getItem('authToken');
      if (!authToken) {
        const urlParams = new URLSearchParams(window.location.search);
        authToken = urlParams.get('token');
        if (authToken) {
          localStorage.setItem('authToken', authToken);
        } else {
          alert('Authorization token is missing.');
          return;
        }
      }
      await fetch('https://next-backend-two.vercel.app/api/handleedit', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${authToken}`
        },
        body: JSON.stringify({
          id: props.id,
          updatedPart: "menu",
          updatedValue: updatedDbData[props.id-1]["menu"],
        }),
      });
    }
  }

  const handleDelete = async (event, editing_part, index) => {
    event.stopPropagation(); // Stops the click event from propagating to the parent element
    if (window.confirm(`${props.submenu["Ürünler"][index]} ürününü silmek istediğinize emin misiniz?`)) {
      const updatedDbData = [...dbData];
      updatedDbData[props.id-1]["menu"][props.subName]["Ürünler"].splice(index, 1);
      updatedDbData[props.id-1]["menu"][props.subName]["Fiyatlar"].splice(index, 1);
      setDbData(updatedDbData);
      let authToken = localStorage.getItem('authToken');
      if (!authToken) {
        const urlParams = new URLSearchParams(window.location.search);
        authToken = urlParams.get('token');
        if (authToken) {
          localStorage.setItem('authToken', authToken);
        } else {
          alert('Authorization token is missing.');
          return;
        }
      }
      await fetch('https://next-backend-two.vercel.app/api/handleedit', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${authToken}`
        },
        body: JSON.stringify({
          id: props.id,
          updatedPart: "menu",
          updatedValue: updatedDbData[props.id-1]["menu"],
        }),
      });
    }
  }

  const handleInfoEdit = async (event, editing_part, index) => {
    event.stopPropagation(); // Stops the click event from propagating to the parent element
    let current_value = "";
    if (!dbData[props.id-1]["menu"][props.subName]["Bilgi"]) {
      dbData[props.id-1]["menu"][props.subName]["Bilgi"] = {};
    }
    else {
      current_value = dbData[props.id-1]["menu"][props.subName]["Bilgi"][editing_part];
    }
    const info = prompt(`${editing_part} için bilgi kutucuğunu düzenleyin: (Boş bırakmanız durumunda bilgi kutucuğu silinecektir!)`, current_value);
    if (info === null) {
      return; // User clicked cancel
    } else if (info !== "") {
      const updatedDbData = [...dbData];
      updatedDbData[props.id-1]["menu"][props.subName]["Bilgi"][editing_part] = info;
      setDbData(updatedDbData);
      let authToken = localStorage.getItem('authToken');
      if (!authToken) {
        const urlParams = new URLSearchParams(window.location.search);
        authToken = urlParams.get('token');
        if (authToken) {
          localStorage.setItem('authToken', authToken);
        } else {
          alert('Authorization token is missing.');
          return;
        }
      }
      await fetch('https://next-backend-two.vercel.app/api/handleedit', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${authToken}`
        },
        body: JSON.stringify({
          id: props.id,
          updatedPart: "menu",
          updatedValue: updatedDbData[props.id-1]["menu"],
        }),
      });
    }
    else {
      const updatedDbData = [...dbData];
      delete updatedDbData[props.id-1]["menu"][props.subName]["Bilgi"][editing_part];
      setDbData(updatedDbData);
      let authToken = localStorage.getItem('authToken');
      if (!authToken) {
        const urlParams = new URLSearchParams(window.location.search);
        authToken = urlParams.get('token');
        if (authToken) {
          localStorage.setItem('authToken', authToken);
        } else {
          alert('Authorization token is missing.');
          return;
        }
      }
      await fetch('https://next-backend-two.vercel.app/api/handleedit', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${authToken}`
        },
        body: JSON.stringify({
          id: props.id,
          updatedPart: "menu",
          updatedValue: updatedDbData[props.id-1]["menu"],
        }),
      });
    }
  }

  const handleInfoShow = (event, item, index) => {
    event.stopPropagation(); // Stops the click event from propagating to the parent element
    const current_info = dbData[props.id-1] && dbData[props.id-1]["menu"] && dbData[props.id-1]["menu"][props.subName] && dbData[props.id-1]["menu"][props.subName]["Bilgi"] && dbData[props.id-1]["menu"][props.subName]["Bilgi"][item] ? dbData[props.id-1]["menu"][props.subName]["Bilgi"][item] : "";
    setLabel(current_info); // Update the label state variable
    setLabelPosition({ top: event.clientY, left: event.clientX }); // Update the label position state variable
  }

  const handleInfoHide = () => {
    setLabel("");
  }

  if(props.admin){ // ADMIN PANEL
    const menuItems = props.submenu["Ürünler"].map((item, index) => (
      <tr className="border-b border-neutral-200 transition duration-300 ease-in-out dark:border-white/10 md:text-base text-sm">
      <td className="whitespace-nowrap px-6 py-4 font-medium text-left text-wrap flex items-center">{<button onClick={(event) => handleDelete(event, "Ürünler", index)}><img src={deleteIcon} alt="Sil" className="block h-4 md:h-5 px-1" /></button>}{item}<button onClick={(event) => handleEdit(event, "Ürünler", index)}><img src={edit} alt="Düzenle" className="block h-4 md:h-5 px-1" /></button><button onClick={(event) => handleInfoEdit(event, item, index)}><img src={info} alt="Bilgi" className="block h-4 md:h-5 px-1" /></button></td>
      <td className="whitespace-nowrap px-6 py-4 text-right"><button onClick={(event) => handleEdit(event, "Fiyatlar", index)}><img src={edit} alt="Düzenle" className="block h-4 md:h-5 px-1" /></button>{parseInt(props.submenu["Fiyatlar"][index], 10) !== 0 ? props.submenu["Fiyatlar"][index] + "₺" : "-"}</td>
      </tr>
    ));
  
    return (
      <div>
        <div className="flex flex-col md:text-base text-sm">
          <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 sm:px-4 lg:px-6">
              <div className="overflow-hidden">
                <table
                  className="min-w-full text-left text-sm font-light text-surface dark:text-white">
                  <thead
                    className="border-b border-neutral-200 font-medium dark:border-white/10">
                    <tr>
                      <th scope="col" className="px-6 py-4 text-left">Ürün Adı</th>
                      <th scope="col" className="px-6 py-4 text-right">Fiyat</th>
                    </tr>
                  </thead>
                  <tbody>
                    {menuItems}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        {label && <div className="label-display" style={{ position: 'absolute', top: labelPosition.top, left: labelPosition.left, backgroundColor: 'rgba(0, 0, 0, 0.75)', color: 'white', padding: '5px 10px', borderRadius: '5px', zIndex: 1000 }}>{label}</div>} {/* Conditionally render the label */}
      </div>
    )
  }
  else { // HOME PAGE
    const menuItems = props.submenu["Ürünler"].map((item, index) => {
      const hasInfo = dbData[props.id-1] && dbData[props.id-1]["menu"] && dbData[props.id-1]["menu"][props.subName] && dbData[props.id-1]["menu"][props.subName]["Bilgi"] && dbData[props.id-1]["menu"][props.subName]["Bilgi"][item];
      return (
      <tr className="border-b border-neutral-200 transition duration-300 ease-in-out dark:border-white/10 md:text-base text-sm">
        <td className="whitespace-nowrap px-6 py-4 font-medium text-left text-wrap flex items-center">
        {item} 
        {hasInfo && (
          <button label={label} onClick={(event) => handleInfoShow(event, item, index)} onMouseEnter={(event) => handleInfoShow(event, item, index)} onMouseLeave={handleInfoHide}>
          <img src={info} alt="Bilgi" className="block h-4 md:h-5 px-1" />
          </button>
        )}
        </td>
        <td className="whitespace-nowrap px-6 py-4 text-right">{parseInt(props.submenu["Fiyatlar"][index], 10) ? props.submenu["Fiyatlar"][index] + "₺" : "-"}</td>
      </tr>
      );
    });
  
    return (
      <div>
        <div className="flex flex-col md:text-base text-sm">
          <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 sm:px-4 lg:px-6">
              <div className="overflow-hidden">
                <table
                  className="min-w-full text-left text-sm font-light text-surface dark:text-white">
                  <thead
                    className="border-b border-neutral-200 font-medium dark:border-white/10">
                    <tr>
                      <th scope="col" className="px-6 py-4 text-left">Ürün Adı</th>
                      <th scope="col" className="px-6 py-4 text-right">Fiyat</th>
                    </tr>
                  </thead>
                  <tbody>
                    {menuItems}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        {label && (
      <div
        className="label-display"
        style={{
          position: 'fixed',
          top: labelPosition.top,
          left: labelPosition.left,
          backgroundColor: 'rgba(0, 0, 0, 0.75)',
          color: 'white',
          padding: '5px 10px',
          borderRadius: '5px',
          zIndex: 1000,
        }}
      >
        {label}
      </div>
    )} {/* Conditionally render the label */}
      </div>
    )
  }
}