import React, { useState, useEffect } from 'react';

const AddNormalModal = ({ onClose, db, resIndex }) => {
  const [menuItems, setMenuItems] = useState([{ name: '', price: '' }]);
  const [numRows, setNumRows] = useState(1);
  const [categoryName, setCategoryName] = useState('');


  const handleNumRowsChange = (event) => {
    let value = parseInt(event.target.value, 10);
    if (isNaN(value)) value = 1;
    if (value > 25) value = 25; // Enforce max limit
    if (value < 1) value = 1; // Enforce min limit
    setNumRows(value);
    const newMenuItems = Array.from({ length: value }, (_, index) => menuItems[index] || { name: '', price: '' });
    setMenuItems(newMenuItems);
  };

  const handleInputChange = (index, event) => {
    const values = [...menuItems];
    values[index][event.target.name] = event.target.value;
    setMenuItems(values);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!categoryName.trim()) {
      alert('Kategori adı boş bırakılamaz.');
      return;
    }
    for (const item of menuItems) {
      if (!item.name.trim() || !item.price.trim()) {
        alert('Ürün adı ve fiyatı boş bırakılamaz.');
        return;
      }
    }

    if (window.confirm(`${categoryName} kategorisini eklemek istediğinize emin misiniz?`)) {
      let updatedMenu = db[resIndex]["menu"];
      updatedMenu[categoryName] = {
        "Ürünler": menuItems.map(item => item.name),
        "Fiyatlar": menuItems.map(item => item.price)
      };
      const newMenu = { [categoryName]: updatedMenu[categoryName], ...updatedMenu };
      updatedMenu = newMenu;
      let authToken = localStorage.getItem('authToken');
      if (!authToken) {
        const urlParams = new URLSearchParams(window.location.search);
        authToken = urlParams.get('token');
        if (authToken) {
          localStorage.setItem('authToken', authToken);
        } else {
          alert('Authorization token is missing.');
          return;
        }
      }
      await fetch('https://next-backend-two.vercel.app/api/handleedit', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${authToken}`
        },
        body: JSON.stringify({
          id: db[resIndex].id,
          updatedPart: "menu",
          updatedValue: updatedMenu,
        }),
      });
      onClose();
      window.location.reload();
    }
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
      <div className="bg-menuBG p-4 rounded-md md:w-[35%] w-[80%] max-h-[90vh] overflow-y-auto">
        <h2 className="text-xl mb-4 text-white">Kategori Ekle</h2>
        <form onSubmit={handleSubmit}>
          <div className="mb-4 flex flex-row justify-start items-center">
            <label className="text-white">Satır Sayısı:</label>
            <input
              type="number"
              value={numRows}
              onChange={handleNumRowsChange}
              className="ml-2 px-2 py-1 border border-gray-300 rounded-md w-[40%] text-black"
              min="1"
              max="25"
            />
          </div>
          <div className="mb-4 flex flex-row justify-start items-center">
            <label className="text-white">Kategori Adı:</label>
              <input
                type="text"
                value={categoryName}
                onChange={(e) => setCategoryName(e.target.value)}
                className="ml-2 px-2 py-1 border border-gray-300 rounded-md w-[60%] text-black"
                placeholder="Kategori Adı"
              />
          </div>
          <table className="min-w-full text-left text-sm font-light text-surface dark:text-white">
            <thead className="border-b border-neutral-200 font-medium dark:border-white/10">
              <tr>
                <th scope="col" className="px-1 py-1 text-center">Ürün Adı</th>
                <th scope="col" className="px-1 py-1 text-center">Fiyat</th>
              </tr>
            </thead>
            <tbody>
              {menuItems.map((item, index) => (
                <tr key={index} className="border-b border-neutral-200 transition duration-300 ease-in-out hover:bg-neutral-100 dark:border-white/10 dark:hover:bg-neutral-600">
                  <td className="whitespace-nowrap px-1 py-4">
                    <input
                      type="text"
                      name="name"
                      value={item.name}
                      onChange={event => handleInputChange(index, event)}
                      className="w-full px-2 py-1 border border-gray-300 rounded-md text-black"
                      placeholder="Ürün Adı"
                    />
                  </td>
                  <td className="whitespace-nowrap px-1 py-4 text-right">
                    <input
                      type="number"
                      name="price"
                      value={item.price}
                      onChange={event => handleInputChange(index, event)}
                      onKeyDown={event => {
                        if (!/[0-9]/.test(event.key) && event.key !== 'Backspace' && event.key !== 'ArrowLeft' && event.key !== 'ArrowRight') {
                          event.preventDefault();
                        }
                      }}
                      className="w-full px-2 py-1 border border-gray-300 rounded-md text-black"
                      placeholder="Fiyat"
                      min="0"
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <div className='flex gap-4 justify-center'>
            <button type="submit" className="bg-blue-500 rounded-md px-4 py-2 mt-4 text-white">Kaydet</button>
            <button type="button" onClick={onClose} className="bg-red-500 rounded-md px-4 py-2 mt-4 text-white">Kapat</button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AddNormalModal;