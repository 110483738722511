import React from 'react';
import { useState, useEffect } from 'react';
import { useNavigate} from 'react-router-dom';
import CryptoJS from 'crypto-js';

if (window.location.href === 'https://www.iytesepeti.com/giris') {
  window.location.replace('https://iytesepeti.com/giris');
}

if (localStorage.getItem('authToken')) {
  localStorage.removeItem('authToken');
}

function Panel() {
  const [isLoading, setIsLoading] = useState(false); // Loading state is used to prevent rendering before database call.

  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  let navigate = useNavigate();

  const HandleLogin = async (e) => {
    e.preventDefault();
    const hashedPassword = CryptoJS.SHA256(password).toString();
    const response = await fetch('https://next-backend-two.vercel.app/api/adminpanel', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({ username, hashedPassword })
    });

    const data = await response.json();
    if (data.success) {
        alert('Giriş başarılı!');
        localStorage.setItem('authToken', data.authToken);
        navigate('/admin?token=' + encodeURIComponent(data.authToken) + '&id=' + encodeURIComponent(data.restaurantId), { state: { from: '/giris' } });
    } else {
        alert('Yanlış şifre ya da kullanıcı adı!');
    }
  };

  if (isLoading) {
    return (
      <div className="bg-siteBG min-h-screen flex justify-center items-center">
        <div className="inline-block h-8 w-8 animate-spin rounded-full border-4 border-solid border-current border-r-transparent align-[-0.125em] text-neutral-100 motion-reduce:animate-[spin_1.5s_linear_infinite]"
          role="status">
          <span className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]">Loading...</span>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen flex items-start justify-center bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-md w-full space-y-8">
        <div>
          <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
            iyte | sepeti - Admin
          </h2>
        </div>
        <form className="mt-8 space-y-6" onSubmit={HandleLogin}>
          <input type="hidden" name="remember" value="true" />
          <div className="rounded-md shadow-sm -space-y-px">
            <div>
              <label htmlFor="username" className="sr-only">Kullanıcı Maili</label>
              <input id="username" name="username" type="text" autoComplete="username" required className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm" placeholder="Kullanıcı Adı" onChange={e => setUsername(e.target.value)} onKeyDown={e => e.key === ' ' && e.preventDefault()}  />
            </div>
            <div>
              <label htmlFor="password" className="sr-only">Şifre</label>
              <input id="password" name="password" type="password" autoComplete="current-password" required className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-b-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm" placeholder="Şifre" onChange={e => setPassword(e.target.value)} onKeyDown={e => e.key === ' ' && e.preventDefault()}  />
            </div>
          </div>
          <div>
            <button type="submit" className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
              Giriş Yap
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default Panel;