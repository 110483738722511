import React, { useState, useEffect } from 'react';
import edit from '../assets/edit_2.svg';
import info from '../assets/info.svg';
import deleteIcon from '../assets/delete.svg';


export default function MenuItem(props) {
  const [dbData, setDbData] = useState(props.db); // Database data is used to store the data from the database.
  const [label, setLabel] = useState(""); // State variable to store the label
  const [labelPosition, setLabelPosition] = useState({ top: 0, left: 0 }); // State variable to store the label position

  const submenu = { ...props.submenu };
  delete submenu["Bilgi"];
  const menuKeys = Object.keys(submenu);

  const handleEdit = async (event, editing_part, item, index) => {
    event.stopPropagation(); // Stops the click event from propagating to the parent element
    if (editing_part === "Kategori") {
      const current_value = props.id ? item : '';
      const new_value = prompt(`Kategori için yeni değeri girin (şuanki değer: ${current_value}):`, current_value);
      if (new_value && new_value.trim() !== "") {
        const updatedDbData = [...props.db];
        const categoryIndex = updatedDbData[props.id-1]["menu"][props.subName]["Kategoriler"].indexOf(item);
        if (categoryIndex !== -1) {
          updatedDbData[props.id-1]["menu"][props.subName]["Kategoriler"][categoryIndex] = new_value;
        }
        setDbData(updatedDbData);
        let authToken = localStorage.getItem('authToken');
      if (!authToken) {
        const urlParams = new URLSearchParams(window.location.search);
        authToken = urlParams.get('token');
        if (authToken) {
          localStorage.setItem('authToken', authToken);
        } else {
          alert('Authorization token is missing.');
          return;
        }
      }
        await fetch('https://next-backend-two.vercel.app/api/handleedit', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${authToken}`
          },
          body: JSON.stringify({
            id: props.id,
            updatedPart: "menu",
            updatedValue: updatedDbData[props.id-1]["menu"],
          }),
        });
      }
    }
    else if (editing_part === "Ürün") {
      const current_value = props.id ? item : '';
      const new_value = prompt(`Ürün için yeni değeri girin (şuanki değer: ${current_value}):`, current_value);
      if (new_value && new_value.trim() !== "") {
        const updatedDbData = [...props.db];
        updatedDbData[props.id-1]["menu"][props.subName][new_value] = updatedDbData[props.id-1]["menu"][props.subName][item];
        delete updatedDbData[props.id-1]["menu"][props.subName][item];
        setDbData(updatedDbData);
        let authToken = localStorage.getItem('authToken');
      if (!authToken) {
        const urlParams = new URLSearchParams(window.location.search);
        authToken = urlParams.get('token');
        if (authToken) {
          localStorage.setItem('authToken', authToken);
        } else {
          alert('Authorization token is missing.');
          return;
        }
      }
        await fetch('https://next-backend-two.vercel.app/api/handleedit', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${authToken}`
          },
          body: JSON.stringify({
            id: props.id,
            updatedPart: "menu",
            updatedValue: updatedDbData[props.id-1]["menu"],
          }),
        });
      }
    }
    else if (editing_part === "Fiyat") {
      const current_value = props.id ? submenu[item][index] : '';
      let new_value = prompt(`Fiyat için yeni değeri girin (şuanki değer: ${current_value}):`, current_value);
      new_value = parseInt(new_value, 10);

      if (isNaN(new_value)) {
        alert("Geçersiz değer girdiniz. Lütfen bir sayı girin.");
        return;
      }

      if (new_value && new_value.trim() !== "") {
        const updatedDbData = [...props.db];
        updatedDbData[props.id-1]["menu"][props.subName][item][index] = new_value;
        setDbData(updatedDbData);
        let authToken = localStorage.getItem('authToken');
      if (!authToken) {
        const urlParams = new URLSearchParams(window.location.search);
        authToken = urlParams.get('token');
        if (authToken) {
          localStorage.setItem('authToken', authToken);
        } else {
          alert('Authorization token is missing.');
          return;
        }
      }
        await fetch('https://next-backend-two.vercel.app/api/handleedit', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${authToken}`
          },
          body: JSON.stringify({
            id: props.id,
            updatedPart: "menu",
            updatedValue: updatedDbData[props.id-1]["menu"],
          }),
        });
      }
    }
  }

  const handleDelete = async (event, editing_part) => {
    event.stopPropagation(); // Stops the click event from propagating to the parent element
    if(window.confirm(`${editing_part} ürününü silmek istediğinize emin misiniz?`)){
      const updatedDbData = [...props.db];
      delete updatedDbData[props.id-1]["menu"][props.subName][editing_part];
      setDbData(updatedDbData);
      let authToken = localStorage.getItem('authToken');
      if (!authToken) {
        const urlParams = new URLSearchParams(window.location.search);
        authToken = urlParams.get('token');
        if (authToken) {
          localStorage.setItem('authToken', authToken);
        } else {
          alert('Authorization token is missing.');
          return;
        }
      }
      await fetch('https://next-backend-two.vercel.app/api/handleedit', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${authToken}`
        },
        body: JSON.stringify({
          id: props.id,
          updatedPart: "menu",
          updatedValue: updatedDbData[props.id-1]["menu"],
        }),
      });
    }
  }

  const handleInfoEdit = async (event, editing_part) => {
    event.stopPropagation(); // Stops the click event from propagating to the parent element
    let current_value = "";
    if (!dbData[props.id-1]["menu"][props.subName]["Bilgi"]) {
      dbData[props.id-1]["menu"][props.subName]["Bilgi"] = {};
    }
    else {
      current_value = dbData[props.id-1]["menu"][props.subName]["Bilgi"][editing_part];
    }
    const info = prompt(`${editing_part} için bilgi kutucuğunu düzenleyin: (Boş bırakmanız durumunda bilgi kutucuğu silinecektir!)`, current_value);
    if (info === null) {
      return; // User clicked cancel
    } else if (info !== "") {
      const updatedDbData = [...dbData];
      updatedDbData[props.id-1]["menu"][props.subName]["Bilgi"][editing_part] = info;
      setDbData(updatedDbData);
      let authToken = localStorage.getItem('authToken');
      if (!authToken) {
        const urlParams = new URLSearchParams(window.location.search);
        authToken = urlParams.get('token');
        if (authToken) {
          localStorage.setItem('authToken', authToken);
        } else {
          alert('Authorization token is missing.');
          return;
        }
      }
      await fetch('https://next-backend-two.vercel.app/api/handleedit', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${authToken}`
        },
        body: JSON.stringify({
          id: props.id,
          updatedPart: "menu",
          updatedValue: updatedDbData[props.id-1]["menu"],
        }),
      });
    }
    else {
      const updatedDbData = [...dbData];
      delete updatedDbData[props.id-1]["menu"][props.subName]["Bilgi"][editing_part];
      setDbData(updatedDbData);
      let authToken = localStorage.getItem('authToken');
      if (!authToken) {
        const urlParams = new URLSearchParams(window.location.search);
        authToken = urlParams.get('token');
        if (authToken) {
          localStorage.setItem('authToken', authToken);
        } else {
          alert('Authorization token is missing.');
          return;
        }
      }
      await fetch('https://next-backend-two.vercel.app/api/handleedit', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${authToken}`
        },
        body: JSON.stringify({
          id: props.id,
          updatedPart: "menu",
          updatedValue: updatedDbData[props.id-1]["menu"],
        }),
      });
    }
  }

  const handleInfoShow = (event, item, index) => {
    event.stopPropagation(); // Stops the click event from propagating to the parent element
    const current_info = dbData[props.id-1] && dbData[props.id-1]["menu"] && dbData[props.id-1]["menu"][props.subName] && dbData[props.id-1]["menu"][props.subName]["Bilgi"] && dbData[props.id-1]["menu"][props.subName]["Bilgi"][item] ? dbData[props.id-1]["menu"][props.subName]["Bilgi"][item] : "";
    setLabel(current_info); // Update the label state variable
    setLabelPosition({ top: event.clientY, left: event.clientX }); // Update the label position state variable
  }

  const handleInfoHide = () => {
    setLabel("");
  }

  const menuHeadArr = submenu["Kategoriler"];
  const menuHeadItem = menuHeadArr ? menuHeadArr.map((item, index) => {    // Render menu category header
    return <th scope="col" className="px-6 py-4">{item}{props.admin && <button onClick={(event) => handleEdit(event,"Kategori", item)}><img src={edit} alt="Düzenle" className="block h-4 md:h-5 px-1" /></button>}</th>
  }) : null;

  const menuItems = menuKeys ?  menuKeys.map((key) => {
    if (key === "Kategoriler") return null;
    const hasInfo = dbData[props.id-1] && dbData[props.id-1]["menu"] && dbData[props.id-1]["menu"][props.subName] && dbData[props.id-1]["menu"][props.subName]["Bilgi"] && dbData[props.id-1]["menu"][props.subName]["Bilgi"][key];
    return (
      <tr className="border-b border-neutral-200 transition duration-300 ease-in-out dark:border-white/10">
        <td className="whitespace-nowrap px-6 py-4 font-medium flex items-center gap-[2px] md:text-base">
          {props.admin && <button onClick={(event) => handleDelete(event, key)}><img src={deleteIcon} alt="Düzenle" className="block h-4 md:h-5 px-1" /></button>} 
          {key} 
          {props.admin && <button onClick={(event) => handleEdit(event, "Ürün", key)}><img src={edit} alt="Düzenle" className="block h-4 md:h-5 px-1" /></button>}
          {props.admin && <button onClick={(event) => handleInfoEdit(event, key)}><img src={info} alt="Düzenle" className="block h-4 md:h-5 px-1" /></button>}
          {!props.admin && hasInfo && (
            <button label={label} onClick={(event) => handleInfoShow(event, key)} onMouseEnter={(event) => handleInfoShow(event, key)} onMouseLeave={handleInfoHide}>
              <img src={info} alt="Bilgi" className="block h-4 md:h-5 px-1" />
            </button>
          )}
        </td>
        {submenu[key].map((item, index) => (
          item!=0 ? <td className="whitespace-nowrap px-6 py-4 md:text-base">{item}₺ {props.admin && <button onClick={(event) => handleEdit(event, "Fiyat", key, index)}><img src={edit} alt="Düzenle" className="block h-4 md:h-5 px-1" /></button>}</td> : <td className="whitespace-nowrap px-6 py-4">- {props.admin && <button onClick={(event) => handleEdit(event, "Fiyat", key, index)}><img src={edit} alt="Düzenle" className="block h-4 md:h-5 px-1" /></button>}</td>
        ))}
      </tr>
    );
  }) : null;

  if (props.admin) { // ADMIN PANEL
    return (
      <div>
        <div className="flex flex-col">
          <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 sm:px-4 lg:px-6">
              <div className="overflow-hidden">
                <table
                  className="min-w-full text-left text-sm font-light text-surface dark:text-white">
                  <thead
                    className="border-b border-neutral-200 font-medium dark:border-white/10">
                    <tr>
                      <th scope="col" className="px-6 py-4">Ürün Adı</th>
                      {menuHeadItem}
                    </tr>
                  </thead>
                  <tbody>
                    {menuItems}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
  else { // HOME PAGE
    return (
      <div>
        <div className="flex flex-col">
          <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 sm:px-4 lg:px-6">
              <div className="overflow-hidden">
                <table
                  className="min-w-full text-left text-sm font-light text-surface dark:text-white">
                  <thead
                    className="border-b border-neutral-200 font-medium dark:border-white/10">
                    <tr>
                      <th scope="col" className="px-6 py-4">Ürün Adı</th>
                      {menuHeadItem}
                    </tr>
                  </thead>
                  <tbody>
                    {menuItems}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        {label && (
          <div
            className="label-display"
            style={{
              position: 'fixed',
              top: labelPosition.top,
              left: labelPosition.left,
              backgroundColor: 'rgba(0, 0, 0, 0.75)',
              color: 'white',
              padding: '5px 10px',
              borderRadius: '5px',
              zIndex: 1000,
            }}
          >
            {label}
          </div>
        )}
      </div>
    )
  }
}