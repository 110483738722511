import React, { useState, useEffect } from 'react';

const AddTableModal = ({ onClose, db, resIndex }) => {
  const [menuItems, setMenuItems] = useState([{ name: '', prices: ['', ''] }]);
  const [numRows, setNumRows] = useState(1);
  const [numCols, setNumCols] = useState(2);
  const [categoryName, setCategoryName] = useState('');
  const [subCategoryNames, setSubCategoryNames] = useState(['', '']);

  const handleNumRowsChange = (event) => {
    let value = parseInt(event.target.value, 10);
    if (isNaN(value)) value = 1;
    if (value > 25) value = 25; // Enforce max limit
    if (value < 1) value = 1; // Enforce min limit
    setNumRows(value);
    const newMenuItems = Array.from({ length: value }, (_, rowIndex) => menuItems[rowIndex] || { name: '', prices: Array(numCols).fill('') });
    setMenuItems(newMenuItems);
  };

  const handleNumColsChange = (event) => {
    let value = parseInt(event.target.value, 10);
    if (isNaN(value)) value = 2;
    if (value > 10) value = 10; // Enforce max limit
    if (value < 2) value = 2; // Enforce min limit
    setNumCols(value);
    const newMenuItems = menuItems.map(row => ({ ...row, prices: Array.from({ length: value }, (_, colIndex) => row.prices[colIndex] || '') }));
    setMenuItems(newMenuItems);
    setSubCategoryNames(Array.from({ length: value }, (_, colIndex) => subCategoryNames[colIndex] || ''));
  };

  const handleInputChange = (rowIndex, colIndex, event) => {
    const values = [...menuItems];
    if (event.target.name === 'name') {
      values[rowIndex][event.target.name] = event.target.value;
    } else {
      values[rowIndex].prices[colIndex] = event.target.value;
    }
    setMenuItems(values);
  };

  const handleSubCategoryNameChange = (colIndex, event) => {
    const values = [...subCategoryNames];
    values[colIndex] = event.target.value;
    setSubCategoryNames(values);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!categoryName.trim()) {
      alert("Kategori adı boş bırakılamaz.");
      return;
    }
    if (subCategoryNames.some(name => !name.trim())) {
      alert("Alt kategori adları boş bırakılamaz.");
      return;
    }
    if (menuItems.some(item => !item.name.trim() || item.prices.some(price => !price.trim()))) {
      alert("Ürün adları ve fiyatlar boş bırakılamaz.");
      return;
    }
    if (window.confirm(`${categoryName} kategorisini eklemek istediğinize emin misiniz?`)) {
      let updatedMenu = db[resIndex]["menu"];
      const newCategory = {
        [categoryName]: {
          "Kategoriler": subCategoryNames
        }
      };
      menuItems.forEach(item => {
        newCategory[categoryName][item.name] = item.prices;
      });
      const newMenu = { ...newCategory, ...updatedMenu };
      updatedMenu = newMenu;
      let authToken = localStorage.getItem('authToken');
      if (!authToken) {
        const urlParams = new URLSearchParams(window.location.search);
        authToken = urlParams.get('token');
        if (authToken) {
          localStorage.setItem('authToken', authToken);
        } else {
          alert('Authorization token is missing.');
          return;
        }
      }
      await fetch('https://next-backend-two.vercel.app/api/handleedit', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${authToken}`
        },
        body: JSON.stringify({
          id: db[resIndex].id,
          updatedPart: "menu",
          updatedValue: updatedMenu,
        }),
      });
      onClose();
      window.location.reload();
    }
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
      <div className="bg-menuBG p-4 rounded-md md:w-[60%] w-[80%] max-h-[90vh] overflow-y-auto">
        <h2 className="text-xl mb-4 text-white">Kategori Ekle</h2>
        <form onSubmit={handleSubmit}>
          <div className="mb-4 flex flex-row justify-start items-center">
            <label className="text-white">Satır Sayısı:</label>
            <input
              type="number"
              value={numRows}
              onChange={handleNumRowsChange}
              className="ml-2 px-2 py-1 border border-gray-300 rounded-md w-[40%] text-black"
              min="1"
              max="25"
            />
          </div>
          <div className="mb-4 flex flex-row justify-start items-center">
            <label className="text-white">Sütun Sayısı:</label>
            <input
              type="number"
              value={numCols}
              onChange={handleNumColsChange}
              className="ml-2 px-2 py-1 border border-gray-300 rounded-md w-[40%] text-black"
              min="2"
              max="10"
              defaultValue={2}
            />
          </div>
          <div className="mb-4 flex flex-row justify-start items-center">
            <label className="text-white">Kategori Adı:</label>
              <input
                type="text"
                value={categoryName}
                onChange={(e) => setCategoryName(e.target.value)}
                className="ml-2 px-2 py-1 border border-gray-300 rounded-md w-[60%] text-black"
                placeholder="Kategori Adı"
              />
          </div>
          <div className="overflow-x-auto">
            <table className="min-w-full text-left text-sm font-light text-surface dark:text-white">
              <thead className="border-b border-neutral-200 font-medium dark:border-white/10">
                <tr>
                  <th scope="col" className="px-1 py-1 text-center">Ürün Adı</th>
                  {Array.from({ length: numCols }, (_, colIndex) => (
                    <th key={colIndex} scope="col" className="px-1 py-1 text-center">
                      <input
                        type="text"
                        value={subCategoryNames[colIndex]}
                        onChange={event => handleSubCategoryNameChange(colIndex, event)}
                        className="w-full px-2 py-1 border border-gray-300 rounded-md text-black"
                        placeholder={`Alt Kategori ${colIndex + 1}`}
                      />
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {menuItems.map((row, rowIndex) => (
                  <tr key={rowIndex} className="border-b border-neutral-200 transition duration-300 ease-in-out hover:bg-neutral-100 dark:border-white/10 dark:hover:bg-neutral-600">
                    <td className="whitespace-nowrap px-1 py-4">
                      <input
                        type="text"
                        name="name"
                        value={row.name}
                        onChange={event => handleInputChange(rowIndex, null, event)}
                        className="w-full px-2 py-1 border border-gray-300 rounded-md text-black"
                        placeholder="Ürün Adı"
                      />
                    </td>
                    {row.prices.map((price, colIndex) => (
                      <td key={colIndex} className="whitespace-nowrap px-1 py-4 text-right">
                        <input
                          type="number"
                          name={`price-${colIndex}`}
                          value={price}
                          onChange={event => handleInputChange(rowIndex, colIndex, event)}
                          onKeyDown={event => {
                            if (!/[0-9]/.test(event.key) && event.key !== 'Backspace' && event.key !== 'ArrowLeft' && event.key !== 'ArrowRight') {
                              event.preventDefault();
                            }
                          }}
                          className="w-full px-2 py-1 border border-gray-300 rounded-md text-black"
                          placeholder={`Fiyat ${colIndex + 1}`}
                          min="0"
                        />
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className='flex gap-4 justify-center'>
            <button type="submit" className="bg-blue-500 rounded-md px-4 py-2 mt-4 text-white">Kaydet</button>
            <button type="button" onClick={onClose} className="bg-red-500 rounded-md px-4 py-2 mt-4 text-white">Kapat</button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AddTableModal;