import React from 'react';
import { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import RestaurantDropdown from "../components/RestaurantDropdown";
import AddNormalModal from "../components/AddNormalModal";
import AddTableModal from '../components/AddTableModal';
import DeleteModal from "../components/DeleteModal";

function AdminPanel() {
  let location = useLocation();
  let navigate = useNavigate();

  useEffect(() => {
    if (!location.state || location.state.from !== '/giris') {
      navigate('/giris');
    }
    document.title = "Admin Panel - iyte | sepeti";
  }, [location, navigate]);

  const queryParams = new URLSearchParams(window.location.search);
  const username = queryParams.get('username');
  const userId = queryParams.get('id');

  const [dbData, setDbData] = useState(null); // Database data is used to store the data from the database.
  const [isLoading, setIsLoading] = useState(true); // Loading state is used to prevent rendering before database call.
  const [current_restaurant, setCurrentRestaurant] = useState(null);
  const [resturantIndex, setResturantIndex] = useState(null);

  useEffect(() => {
    fetch('https://next-backend-two.vercel.app/api/restaurants') // TODO: AUTH (use passwords?)
      .then(response => response.json())
      .then(data => {
        const sortedData = data.sort((a, b) => a.id - b.id);
        setDbData(sortedData);
        setResturantIndex(userId-1);
        setCurrentRestaurant(sortedData[userId-1]);
        setIsLoading(false);
      })
      .catch(error => {
        console.error('Error fetching data:', error);
        setIsLoading(false);
      });
  }, []);


  const [addNormalModalStatus, setNormalAddModalStatus] = useState(false);
  const toggleNormalAddModal = () => {
    setNormalAddModalStatus(!addNormalModalStatus);
  }

  const [deleteModalStatus, setDeleteModalStatus] = useState(false);
  const toggleDeleteModal = () => {
    setDeleteModalStatus(!deleteModalStatus);
  }

  const [addTableModalStatus, setTableAddModalStatus] = useState(false);
  const toggleTableAddModal = () => {
    setTableAddModalStatus(!addTableModalStatus);
  }


  if (isLoading) {
    return(
      <div className="bg-siteBG min-h-screen flex justify-center items-center">
        <div className="inline-block h-8 w-8 animate-spin rounded-full border-4 border-solid border-current border-r-transparent align-[-0.125em] text-neutral-100 motion-reduce:animate-[spin_1.5s_linear_infinite]"
        role="status">
        <span className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]">Loading...</span>
        </div>
      </div>
    )
  }
  else if (current_restaurant) {
    const toggleRestaurantStatus = async () => {
      const new_status = current_restaurant.status === "Açık" ? "Kapalı" : "Açık";
      let authToken = localStorage.getItem('authToken');
      if (!authToken) {
        const urlParams = new URLSearchParams(window.location.search);
        authToken = urlParams.get('token');
        if (authToken) {
          localStorage.setItem('authToken', authToken);
        } else {
          alert('Authorization token is missing.');
          return;
        }
      }
      await fetch('https://next-backend-two.vercel.app/api/handleedit', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${authToken}`
        },
        body: JSON.stringify({
          id: resturantIndex + 1,
          updatedPart: "status",
          updatedValue: new_status,
        }),
      });
      window.location.reload();
    }
    return (
      <div className="flex flex-col items-center md:items-start md:px-20 px-5 md:py-10 py-5 w-full min-h-screen bg-siteBG gap-8">
      <div className='flex flex-col md:flex-row gap-5 md:gap-0 items-center md:items-start justify-between w-full'>
        <p1 className="text-white text-3xl md:text-5xl font-bold font-[Roboto]">iyte | sepeti - Admin</p1>
        <p1 className="text-white text-3xl md:text-5xl font-bold font-[Roboto] text-center">{current_restaurant ? current_restaurant.name : ''}</p1>
      </div>
      {current_restaurant ? (
        <>
        <div className='flex flex-col md:flex-row text-white gap-2 font-bold'>
          <div className='flex gap-2'>
          <button className="bg-green-500 rounded-md px-4 py-2" onClick={toggleNormalAddModal}>Kategori Ekle - Normal</button>
          <button className="bg-green-500 rounded-md px-4 py-2" onClick={toggleTableAddModal}>Kategori Ekle - Tablo</button>
          {addNormalModalStatus && <AddNormalModal onClose={toggleNormalAddModal} db={dbData} resIndex={resturantIndex} />}
          {addTableModalStatus && <AddTableModal onClose={toggleTableAddModal} db={dbData} resIndex={resturantIndex} />}
          </div> 
          <button className="bg-red-500 rounded-md px-4 py-2" onClick={toggleDeleteModal}>Kategori Sil</button>
          {deleteModalStatus && <DeleteModal onClose={toggleDeleteModal} db={dbData} resIndex={resturantIndex} />}
        </div>
        <RestaurantDropdown id={current_restaurant.id} name={current_restaurant.name} menu={current_restaurant.menu} status={current_restaurant.status} open_hours={current_restaurant.open_hours} tel={current_restaurant.tel} db={dbData} admin={true} />
        {current_restaurant.status !== "Yok" && <button onClick={toggleRestaurantStatus} className={`mt-4 px-4 py-2 text-white font-bold rounded-md ${current_restaurant.status === "Açık" ? "bg-red-500" : "bg-green-500"}`}>
          {current_restaurant.status === "Açık" ? "Paket Servisi Kapat" : "Paket Servisi Aç"}
        </button>}
        {current_restaurant.status === "Kapalı" && (
          <p className=" text-red-500 font-bold">RESTORANINIZ ŞUAN KAPALI DURUMDADIR.</p>
        )}
        </>
      ) : (
        <p1 className="text-white"></p1>
      )}
      </div>
    );
  }
  
}

export default AdminPanel;