import TableMenu from './TableMenu';
import NormalMenu from './NormalMenu';
import { useState, useEffect } from 'react';
import edit from '../assets/edit_2.svg';
import add from '../assets/add.svg';

function MenuDropdown(props) {
  const [isOpen, setIsOpen] = useState(false);
  useEffect(() => {props.isOpen ? setIsOpen(isOpen) : setIsOpen(false)}, [props.isOpen]);

  const [dbData, setDbData] = useState(props.db); // Database data is used to store the data from the database.
  const handleEdit = async (event, editing_part) => {
    event.stopPropagation(); // Stops the click event from propagating to the parent element
    const current_value = props.id ? dbData[props.id-1]["menu"][editing_part] : '';
    const new_value = prompt(`Menü için yeni değeri girin (şuanki değer: ${editing_part}):`, editing_part);
    if (new_value && new_value !== current_value) {
      const updatedDbData = [...dbData];
      const menu = updatedDbData[props.id-1]["menu"];
      const newMenu = {};

      Object.keys(menu).forEach(key => {
        if (key === editing_part) {
          newMenu[new_value] = menu[key];
        } else {
          newMenu[key] = menu[key];
        }
      });

      updatedDbData[props.id-1]["menu"] = newMenu
      setDbData(updatedDbData);
      let authToken = localStorage.getItem('authToken');
      if (!authToken) {
        const urlParams = new URLSearchParams(window.location.search);
        authToken = urlParams.get('token');
        if (authToken) {
          localStorage.setItem('authToken', authToken);
        } else {
          alert('Authorization token is missing.');
          return;
        }
      }
      await fetch('https://next-backend-two.vercel.app/api/handleedit', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${authToken}`
        },
        body: JSON.stringify({
          id: props.id,
          updatedPart: "menu",
          updatedValue: updatedDbData[props.id-1]["menu"],
        }),
      });
      window.location.reload();
    }
  }
  const handleAdd = async (event, editing_part) => {
    event.stopPropagation(); // Stops the click event from propagating to the parent element
    if (!props.table) {
      const product_name = prompt(`${editing_part} kategorisine eklemek istediğiniz ürün adını girin:`);
      if (product_name) {
        const product_price = prompt(`${editing_part} kategorisine eklemek istediğiniz ürün fiyatını girin:`);
        if (product_price) {
          const updatedDbData = [...dbData];
          updatedDbData[props.id-1]["menu"][editing_part]["Ürünler"].push(product_name);
          updatedDbData[props.id-1]["menu"][editing_part]["Fiyatlar"].push(product_price);
          setDbData(updatedDbData);
          let authToken = localStorage.getItem('authToken');
      if (!authToken) {
        const urlParams = new URLSearchParams(window.location.search);
        authToken = urlParams.get('token');
        if (authToken) {
          localStorage.setItem('authToken', authToken);
        } else {
          alert('Authorization token is missing.');
          return;
        }
      }
          await fetch('https://next-backend-two.vercel.app/api/handleedit', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${authToken}`
            },
            body: JSON.stringify({
              id: props.id,
              updatedPart: "menu",
              updatedValue: updatedDbData[props.id-1]["menu"],
            }),
          });
        }
      }
    }
    else {
      const product_name = prompt(`${editing_part} kategorisine eklemek istediğiniz ürün adını girin:`);
      if (product_name) {
        const updatedDbData = [...dbData];
        updatedDbData[props.id-1]["menu"][editing_part][product_name] = [];
        for (const category of updatedDbData[props.id-1]["menu"][editing_part]["Kategoriler"]) {
          const product_price = prompt(`${category} alt kategorisindeki ${product_name} ürünü için fiyat girin:`);
          if (product_price) {
            updatedDbData[props.id-1]["menu"][editing_part][product_name].push(product_price);
          } else {
            break;
          }
        }
        if (updatedDbData[props.id-1]["menu"][editing_part][product_name].length === updatedDbData[props.id-1]["menu"][editing_part]["Kategoriler"].length) {
          setDbData(updatedDbData);
          let authToken = localStorage.getItem('authToken');
      if (!authToken) {
        const urlParams = new URLSearchParams(window.location.search);
        authToken = urlParams.get('token');
        if (authToken) {
          localStorage.setItem('authToken', authToken);
        } else {
          alert('Authorization token is missing.');
          return;
        }
      }
          await fetch('https://next-backend-two.vercel.app/api/handleedit', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${authToken}`
            },
            body: JSON.stringify({
              id: props.id,
              updatedPart: "menu",
              updatedValue: updatedDbData[props.id-1]["menu"],
            }),
          });
        }
      }
    }
  }
  // ADMIN PANEL
  if(props.admin){
    return (
      <div className="bg-menuBG text-white text-base border border-gray-600">
        <button onClick={() => setIsOpen(!isOpen)} className='w-full px-5 py-3 flex gap-2 items-center'>
          <p className='md:text-lg text-sm'>{isOpen ? '▾' : '▸'}</p><p className='font-bold md:text-lg text-sm text-left'>{props.name}</p><button onClick={(event) => handleEdit(event, props.name)}><img src={edit} alt="Düzenle" className="block h-4 md:h-5 px-1" /></button><button onClick={(event) => handleAdd(event, props.name)}><img src={add} alt="Düzenle" className="block h-4 md:h-5 px-1" /></button>
        </button>
        <div style={{maxHeight: isOpen ? "100%" : "0", transition: "max-height 0.5s ease-in-out", overflow: "hidden"}}> 
          <ul className='md:px-10 px-2 pb-3' style={{opacity: isOpen ? "1" : "0", transition: "opacity 0.5s ease-in-out"}}>
            {props.table ? <TableMenu resName={props.resName} submenu={props.submenu} subName={props.name} db={props.db} id={props.id} admin={props.admin} /> : <NormalMenu resName={props.resName} submenu={props.submenu} subName={props.name} db={props.db} id={props.id} admin={props.admin} />}
          </ul>
        </div>
      </div>
    );
  }
  else{ // HOME PAGE
    return (
      <div className="bg-menuBG text-white text-base border border-gray-600">
        <button onClick={() => setIsOpen(!isOpen)} className='w-full px-5 py-3 flex gap-3 items-center'>
          <p className='md:text-lg text-sm'>{isOpen ? '▾' : '▸'}</p><p className='font-bold md:text-lg text-sm'>{props.name}</p>
        </button>
        <div style={{maxHeight: isOpen ? "100%" : "0", transition: "max-height 0.5s ease-in-out", overflow: "hidden"}}> 
          <ul className='md:px-10 px-2 pb-3' style={{opacity: isOpen ? "1" : "0", transition: "opacity 0.5s ease-in-out"}}>
            {props.table ? <TableMenu resName={props.resName} submenu={props.submenu} subName={props.name} db={props.db} id={props.id} admin={props.admin} /> : <NormalMenu resName={props.resName} submenu={props.submenu} subName={props.name} db={props.db} id={props.id} admin={props.admin} />}
          </ul>
        </div>
      </div>
    );
  }
}

export default MenuDropdown;