import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { useEffect } from 'react';
import Home from './pages/Home';
import LoginPanel from './pages/LoginPanel';
import AdminPanel from './pages/AdminPanel';


import RestaurantPage from './pages/RestaurantPage';
import NotFound from './pages/NotFound';  

function App() {
  if (window.location.href === 'https://www.iytesepeti.com/') {
    window.location.replace('https://iytesepeti.com/');
    return null;
  }
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/giris" element={<LoginPanel />} />
        <Route path="/admin" element={<AdminPanel />} />
        <Route path="/menu/:restaurant_name" element={<RestaurantPage />} />
        <Route path="*" element={<NotFound />} /> {/* Catch-all route for undefined pages */}
      </Routes>
    </Router>
  );
}

export default App;